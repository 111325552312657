$video-padding: 4rem;

[data-content-entry="homepage"] {
    height: 100dvh;

    @media screen and (min-width: $tbl) {
        height: 700vw;
        overflow: hidden;
    }
}

.home .header.header--static {
    display: none;
}

.home-slider {
    @include flex(flex-start, flex-start, 0, row);

    width: fit-content;
    position: absolute;
    top: 0;
    background-color: $black;
    will-change: transform;

    &__scroller {
        width: 100vw;
        overflow: hidden;
        z-index: 3;
        position: relative;
        height: 100dvh;

        @media screen and (min-width: $tbl) {
            height: 700vw;
        }
    }

    &__video {
        position: relative;
        width: 100vw;
        height: 100dvh;
        overflow: hidden;

        &__inner {
            width: 100%;
            height: 100%;
            overflow: hidden;
            position: relative;
            display: block;

            &__video {
                position: relative;
                overflow: hidden;
                width: 100%;
                height: 100%;
                filter: brightness(0.6);

                & video,
                & img {
                    width: 100vw;
                    height: 100lvh;
                    object-fit: cover;
                    overflow: hidden;
                    position: absolute;
                    top: 0;
                    left: 0;

                    @media screen and (min-width: $tbl) {
                        transform: scale(1.13);
                    }
                }
            }
        }
    }

    &__content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        z-index: 7;
        transform: translateZ(1px);

        @media screen and (min-width: $tbl) {
            padding: 0 106px;
        }

        &--cta {
            border-radius: 50%;
            background-color: transparent;
            transition: $trans;
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 32px;

            & svg {
                transition: $trans;
                width: 48px;
                height: 48px;

                & path {
                    fill: $white;
                }
            }

            &::after {
                content: "";
                background-image: url(../media/svgs/Plus-icon-hover.svg);
                background-repeat: no-repeat;
                background-size: contain;
                position: absolute;
                width: 48px;
                height: 48px;
                bottom: 0;
                opacity: 0;
                transition: $trans;
            }

            @include hover {
                & svg {
                    opacity: 0;
                }

                &::after {
                    opacity: 1;
                }
            }
        }

        &--inner {
            position: absolute;
            top: 50%;
            left: 50%;
            width: max-content;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            gap: 32px;
            color: $white;
            transform: translate3d(-50%, -50%, 0);
            max-width: 100vw;

            @media screen and (max-width: $lpt) {
                padding: 0 20px;
            }

            p {
                max-width: 495px;
                width: 100%;
                color: $white;

                &.italic {
                    @media screen and (max-width: $mbl-m) {
                        display: none;
                    }
                }
            }

            & .scrollindicator {
                @media screen and (min-width: $tbl) {
                    transform: rotate(90deg);
                }
            }
        }

        &--right {
            max-width: 422px;
            width: 100%;
            color: $white;

            & > * {
                opacity: 0;
            }

            p {
                color: white;
            }

            .scrollindicatorsn {
                display: flex;
                align-items: center;
                gap: 8px;
                position: absolute;
                bottom: 20%;
            }
        }
    }

    &__nav {
        @include flex(flex-start, center, 1rem, row);

        position: fixed;
        max-width: 1250px;
        top: 90dvh;
        left: 50%;
        z-index: 10;
        width: 100vw;
        transform: translateX(-50%);

        @media screen and (max-width: $lpt) {
            width: 18vw;
            justify-content: unset;
        }

        &--number {
            @include flex(flex-start, flex-start, 0.7rem, row);

            position: absolute;
            top: -70px;
            left: 50%;
            transform: translateX(-50%);
            color: $white;
            overflow: hidden;
            padding-left: 16px;

            @media screen and (max-width: $tbl) {
                display: none;
            }

            & .text-small-detail--num {
                @include flex(flex-start, flex-start, 0.2rem, column);

                position: absolute;
                left: 0;
            }
        }

        &--items {
            @include flex(flex-end, center, 1rem, row);

            position: absolute;
            left: calc(50% - (74px / 2));
        }

        &--active {
            position: absolute;
            border: 0.1rem solid $white;
            width: 74px;
            height: 46px;
            z-index: 2;
            left: 50%;
            transform: translateX(-50%);
            border-radius: 4px;
        }

        &__item {
            position: relative;
            border-radius: 4px;
            overflow: hidden;
            width: 74px;
            height: 46px;
            filter: brightness(0.5);
            transition: all 0.5s ease;

            @include hover {
                filter: brightness(0.8);
            }

            &.active {
                filter: brightness(1);
            }

            & img {
                width: 74px;
                height: 46px;
                object-fit: cover;
            }
        }
    }
}
