.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    background: transparent;
    border: 0;
    padding: 24px 106px;
    opacity: 0;
    z-index: 2000;
    transition:
        transform 0.35s ease,
        background-color 0.35s ease,
        opacity 0.35s ease;

    @media screen and (max-width: $lpt) {
        padding: 18px 20px;
    }

    @media screen and (max-width: $tbl) {
        position: absolute;
    }

    &--static {
        @media screen and (min-width: $tbl) {
            display: none;
        }

        @media screen and (max-width: $tbl) {
            background-color: $white;
            position: fixed;

            .header__logo {
                svg {
                    path {
                        fill: $red;
                    }
                }
            }

            .header__central {
                a {
                    color: $black !important;
                }
            }

            .header__toggle {
                span {
                    background: $black;
                }
            }

            .header__homemb {
                svg {
                    path {
                        stroke: $black;
                    }
                }
            }

            &.snheader--white:not(.header--active) + .header {
                .header__logo {
                    svg {
                        path {
                            fill: $red;
                        }
                    }
                }

                .header__central {
                    a {
                        color: $black !important;
                    }
                }

                .header__toggle {
                    span {
                        background: $black;
                    }
                }

                .header__homemb {
                    svg {
                        path {
                            stroke: $black;
                        }
                    }
                }
            }
        }
    }

    &--retract {
        transform: translateY(-100%);

        &--top {
            opacity: 0 !important;
        }
    }

    &.header--active {
        background-color: transparent !important;
        transform: unset !important;

        & .header__logo path {
            fill: $white !important;
        }

        & .header__toggle span {
            background-color: $white !important;
        }

        & .header__central a.text-small-detail {
            color: $white !important;
        }

        & .header__homemb svg path {
            stroke: $white !important;
        }
    }

    &__wrapper {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        z-index: 2;
    }

    &__logo {
        path {
            transition: $trans;
        }
    }

    &__menu {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100dvh;
        padding: 74px 0 0;
        background: rgb(0 0 0 / 40%);
        backdrop-filter: blur(52px);
        opacity: 0;
        visibility: hidden;
        z-index: 5;

        &--hover {
            @media screen and (min-width: $lpt) {
                & .header__menu--list--item {
                    opacity: 0.3;
                }
            }
        }

        &--list {
            display: grid;
            grid-auto-rows: minmax(0, 1fr);
            grid-auto-flow: row;
            list-style: none;
            height: 100%;

            &--item {
                @include flex(flex-start, center, 0, row);

                &:nth-of-type(2n) {
                    justify-content: flex-end;
                }

                padding: 24px 106px;
                overflow: hidden;
                position: relative;
                transition: all 0.7s ease;

                @media screen and (max-width: $lpt) {
                    padding: 24px 20px;
                    text-align: center;
                    justify-content: center !important;
                }

                @include hover {
                    opacity: 1 !important;
                }

                .line {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 1px;
                    background-color: rgb(255 255 255 / 24%);
                    will-change: transform;
                    transform-origin: left;
                }

                &:nth-of-type(2n) .line {
                    transform-origin: right;
                }

                a {
                    span {
                        transition: $trans;
                        display: inline-block;
                        transform-origin: left;
                    }

                    .char {
                        text-rendering: optimizespeed;
                        will-change: transform;
                    }

                    @include hover {
                        span {
                            transform: scale(1.05, 1);
                        }
                    }
                }

                &:nth-child(even) {
                    a {
                        span {
                            transform-origin: right;
                        }
                    }
                }
            }
        }

        &--download {
            display: none;

            @media screen and (max-width: $lpt) {
                display: block;
            }
        }

        &--mbbtn {
            display: none;

            @media screen and (max-width: $lpt) {
                margin-top: 24px;
                display: flex;
                justify-content: center;
                gap: 8px;
            }
        }
    }

    &__download {
        position: absolute;
        top: 3px;
        right: 95px;
        opacity: 0;
        visibility: hidden;

        @media screen and (max-width: $lpt) {
            display: none;
        }
    }

    &__privacy {
        @include flex(flex-start, center, 8px, column);

        position: absolute;
        top: 3px;
        right: 48px;

        @media screen and (max-width: $lpt) {
            display: none;
        }

        &__btn {
            @include flex(center, center, 0, row);

            opacity: 0;
            visibility: hidden;
            border-radius: 50%;
            padding: 3px;
            margin-top: -3px;
            margin-right: -3px;
            transition:
                $trans,
                opacity 0s linear;
            aspect-ratio: 1/1;

            & svg {
                pointer-events: none;

                & path {
                    transition: $trans;
                }
            }

            &.active {
                background-color: $white;

                & path {
                    stroke: $black;
                }

                & + .header__privacy__dropdown {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }

        &__dropdown {
            @include flex(flex-start, flex-start, 8px, column);

            position: absolute;
            top: 40px;
            background-color: $white;
            white-space: nowrap;
            padding: 16px;
            border-radius: 8px;
            overflow: hidden;
            opacity: 0;
            visibility: hidden;
            transition: $trans;

            &--item {
                transition: all 0.1s ease;

                @include hover {
                    color: rgba($color: $black, $alpha: 50%);
                }
            }
        }
    }

    &__central {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        color: $white;

        & a {
            color: inherit !important;
            transition: $trans;
        }

        @media screen and (max-width: $lpt) {
            display: none;
        }
    }

    &__homemb {
        display: none;

        @media screen and (max-width: $lpt) {
            display: flex;
            position: absolute;
            right: 48px;
            margin-top: 5px;

            & path {
                transition: $trans;
            }
        }
    }

    &__toggle {
        background: transparent;
        border: 0;
        outline: 0;
        display: flex;
        gap: 5px;
        flex-direction: column;
        align-items: flex-end;

        span {
            position: relative;
            display: block;
            width: 24px;
            height: 1px;
            background: $white;
            transition: $trans;

            &:nth-child(2) {
                width: 22px;
            }

            &:nth-child(3) {
                width: 20px;
            }
        }

        &.active {
            span {
                width: 24px;

                &:nth-child(1) {
                    transform: translateY(7px) rotate(45deg);
                }

                &:nth-child(2) {
                    opacity: 0;
                }

                &:nth-child(3) {
                    transform: translateY(-5px) rotate(-45deg);
                }
            }
        }
    }

    @media screen and (min-width: $tbl) {
        &.snheader {
            &--out-hero {
                .header__central {
                    opacity: 0 !important;
                    pointer-events: none;
                }
            }

            &--white {
                .header__logo {
                    svg {
                        path {
                            fill: $red;
                        }
                    }
                }

                .header__central {
                    a {
                        color: $black !important;
                    }
                }

                .header__toggle {
                    span {
                        background: $black;
                    }
                }

                .header__homemb {
                    svg {
                        path {
                            stroke: $black;
                        }
                    }
                }
            }

            &--dark {
                .header__logo {
                    svg {
                        path {
                            fill: $white;
                        }
                    }
                }

                .header__central {
                    a {
                        color: $white !important;
                    }
                }

                .header__toggle {
                    span {
                        background: $white;
                    }
                }

                .header__homemb {
                    svg {
                        path {
                            stroke: $white;
                        }
                    }
                }
            }

            &--red {
                .header__logo {
                    svg {
                        path {
                            fill: $white;
                        }
                    }
                }

                .header__central {
                    a {
                        color: $white !important;
                    }
                }

                .header__toggle {
                    span {
                        background: $white;
                    }
                }

                .header__homemb {
                    svg {
                        path {
                            stroke: $white;
                        }
                    }
                }
            }
        }
    }
}
