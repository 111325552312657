.cookie-banner {
    @include flex(space-between, center, 32px, row);

    background-color: $white;
    width: 100vw;
    position: fixed;
    top: 100dvh;
    transform: translateY(100%);
    z-index: 111;
    padding: 24px 106px;
    opacity: 0;
    pointer-events: none;
    transition: $trans;

    @media screen and (max-width: $lpt) {
        padding: 18px 20px;
    }

    @media screen and (max-width: $tbl) {
        flex-direction: column;
        align-items: flex-start;
    }

    &.active {
        opacity: 1;
        pointer-events: all;
        transform: translateY(-100%);
    }

    &.hide {
        display: none;
    }

    &__btns {
        @include flex(flex-start, center, 16px, row);

        @media screen and (max-width: $tbl) {
            flex-wrap: wrap;
        }
    }
}
