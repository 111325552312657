.c-asset {
    position: relative;
    width: 100%;
    height: fit-content;
    overflow: hidden;

    & video,
    & img {
        height: auto;
        width: 100%;
        display: block;
    }

    &__mbl-poster {
        display: none !important;
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
        width: 100%;
        height: 100%;
        pointer-events: none;
        transition: $trans;

        @media screen and (max-width: $mbl-m) {
            display: block !important;
        }
    }

    &--wrap {
        padding: 150px 0;
        max-width: 957px;
        margin: 0 auto;

        @media screen and (max-width: $mbl-l) {
            padding: 80px 0;
        }

        & .c-asset__caption {
            margin-top: 10px;
            max-width: 90%;

            @media screen and (min-width: $mbl-l) {
                margin-top: 20px;
                max-width: 50%;
            }
        }
    }

    &__bottom {
        @include flex(flex-start, center, 0, row);

        position: absolute;
        bottom: 10px;
        left: 20px;
        width: calc(100% - (20px * 2));
        transition: $trans;
    }

    &__timing {
        @include flex(flex-start, flex-start, 5px, row);

        color: $white;
        padding: 10px;
    }

    &__play-pause {
        position: relative;
        width: 15px;
        height: 15px;
        padding: 10px;
        box-sizing: unset;

        & svg {
            position: absolute;
            top: 10px;
            left: 10px;
            width: 15px;
            height: 15px;
            transition: all 0.1s ease;

            &:nth-of-type(1) {
                opacity: 1;
            }

            &:nth-of-type(2) {
                opacity: 0;
            }
        }
    }

    &__bar {
        height: 8px;
        width: 100%;
        padding: 20px 10px;
        margin-top: -3px;
        cursor: pointer;
        transition: $trans;
        position: relative;

        &::before {
            content: "";
            position: absolute;
            top: 20px;
            left: 10px;
            height: 2px;
            width: calc(100% - 20px);
            background-color: rgba($color: $white, $alpha: 20%);
            pointer-events: none;
        }

        &__prog {
            height: 2px;
            width: 0%;
            background-color: $white;
        }
    }

    &__mute {
        cursor: pointer;
        position: relative;
        width: 36px;
        height: 18px;
        padding: 10px;

        & svg {
            opacity: 0;
            position: absolute;
            top: 0;
            left: 10px;
            pointer-events: none;
            height: 100%;
            width: auto;
        }

        & svg {
            &:nth-of-type(1) {
                height: 18.5px;
            }
        }

        &--0 {
            & svg {
                &:nth-of-type(1) {
                    opacity: 1;
                }
            }
        }

        &--100 {
            & svg {
                &:nth-of-type(2) {
                    opacity: 1;
                }
            }
        }
    }

    &__overlay {
        @include flex(center, center, 0, row);

        cursor: pointer;
        width: 100%;
        height: 100%;
        background: linear-gradient(0deg, rgb(0 0 0 / 40%), rgb(0 0 0 / 30%));
        position: absolute;
        pointer-events: none;
        top: 0;
        left: 0;
        transition: $trans;

        & svg {
            width: 67px;
            height: 67px;
        }
    }

    &--hide {
        & .c-asset__bottom {
            opacity: 0;
            pointer-events: none;
        }
    }

    &--playing {
        cursor: default;

        & .c-asset__overlay {
            opacity: 0;
            pointer-events: none;
        }

        & .c-asset__mbl-poster {
            opacity: 0;
            pointer-events: none;
        }

        & .c-asset__play-pause svg {
            &:nth-of-type(1) {
                opacity: 0;
            }

            &:nth-of-type(2) {
                opacity: 1;
            }
        }
    }
}
