html,
body {
    // width: 100vw;
    // color: $black;
    // font-family: $font-secondary;
    // scroll-behavior: unset;
    // overflow: hidden;
    // overscroll-behavior: none;
    font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased;
    max-width: 100vw;
    overflow-x: hidden;
}

body::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &.home {
        background-color: $black;
    }
}

html {
    font-size: 62.5%;
    box-sizing: border-box;

    &.header-focused {
        & .main {
            filter: brightness(0.6) blur(1rem);
        }
    }
}

*,
*::before,
*::after {
    box-sizing: inherit;
    margin: 0;
    padding: 0;
}

.main {
    width: 100%;
    min-height: 100vh;
    position: relative;
    z-index: 2;
    background-color: $white;
    color: $black;
    transition:
        all 0s linear,
        filter 0.5s ease;
    will-change: opacity;
    overflow: hidden;

    &::before {
        z-index: 0 !important;
    }
}

section {
    max-width: 100vw;

    // overflow: hidden;
    position: relative;
    z-index: 1;
}

button,
a {
    appearance: none;
    border: none;
    text-decoration: unset;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    color: inherit;

    &.underline {
        text-decoration: underline;
        transition: color 0.25s ease;

        @include hover {
            color: $black;
        }
    }
}

.pixelated {
    z-index: 10000;
    width: 100%;
    height: 100%;

    & img {
        visibility: hidden;
        pointer-events: none;
        position: absolute;
        width: auto;
        height: auto;
        object-fit: cover;

        &::before {
            display: none;
        }
    }
}

video[autoplay]:not([muted]) {
    display: none;
}

html.lenis {
    height: auto;
}

.lenis.lenis-smooth {
    scroll-behavior: auto;
}

.lenis.lenis-smooth [data-lenis-prevent] {
    overscroll-behavior: contain;
}

.lenis.lenis-stopped,
.lenis.lenis-stopped body {
    height: 100%;
    overflow: hidden;
}

.lenis.lenis-scrolling iframe {
    pointer-events: none;
}

.img-switch {
    &--mbl {
        display: none;

        @media (max-width: $tbl) {
            display: block;

            & + .img-switch--dsk {
                display: none;
            }
        }
    }
}

a {
    @include hover {
        color: unset !important;
    }
}

.justify-center {
    justify-content: center;
}

.items-center {
    align-items: center;
}

.vidconversion {
    position: relative;
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-right: 48px;

    &__left {
        h5 {
            margin-bottom: 32px;
        }

        &--block {
            h5 {
                margin: 0;
                opacity: 0.56;
            }

            &:not(:last-child) {
                margin-bottom: 16px;
            }
        }
    }

    &__right {
        h5 {
            margin-bottom: 32px;
            padding-left: 16px;
        }

        &--block {
            display: flex;
            gap: 8px;
            align-items: center;

            h5 {
                margin: 0;
                opacity: 0.56;
            }

            &:not(:last-child) {
                padding-left: 16px;
                margin-bottom: 40px;
            }
        }
    }
}
