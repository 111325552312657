.nextproject {
    position: relative;
    width: 100%;
    height: 100lvh;

    &__vid {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        filter: brightness(0.6);

        &--layer {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgb(0 0 0 / 1%);
            backdrop-filter: blur(40px);
            z-index: 1;
        }

        video,
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 0 106px;

        // background: linear-gradient(0deg, rgb(0 0 0 / 40%) 0%, rgb(0 0 0 / 40%) 100%);
        color: $white;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        z-index: 2;

        @media screen and (max-width: $lpt) {
            padding: 0 20px;
        }

        &--inner {
            position: absolute;
            top: 50%;
            left: 50%;
            width: max-content;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            gap: 32px;
            color: $white;
            transform: translate3d(-50%, -50%, 0);
            max-width: 100vw;

            @media screen and (max-width: $lpt) {
                padding: 0 20px;
            }

            &.astart {
                align-items: unset;
            }

            p {
                max-width: 495px;
                width: 100%;
                color: $white;
            }
        }

        &--right {
            max-width: 422px;
            width: 100%;
            align-self: flex-end;
            height: calc(50% + 80px);
            color: $white;

            & > * {
                opacity: 0;
            }

            &.on-two-lines {
                height: calc(50% + 120px);
            }

            p {
                color: white;
            }

            .scrollindicatorsn {
                display: flex;
                align-items: center;
                gap: 8px;
                position: absolute;
                bottom: 20%;
            }
        }
    }
}
