[data-barba-namespace="detail"] {
    & .innerhero {
        z-index: 1;

        &__vid {
            filter: brightness(0.6);
        }

        // &__content {
        //     background: linear-gradient(0deg, rgb(0 0 0 / 40%) 0%, rgb(0 0 0 / 40%) 100%);
        // }
    }
}

.innerhero {
    position: relative;
    width: 100%;
    height: 100lvh;
    z-index: -1;

    &__fullvideo {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: $black;
        z-index: 20;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        opacity: 0;
        visibility: hidden;

        &--vid {
            position: relative;
            height: 55vh !important;
            width: fit-content !important;
            transform: scale(0.8);
            opacity: 0;

            video {
                height: 100% !important;
            }
        }

        &--close {
            position: absolute;
            bottom: 30px;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    &__vid {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100lvh;

        video,
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__content {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100%;
        padding: 0 106px;
        color: $white;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media screen and (max-width: $lpt) {
            padding: 120px 20px 20px;
            flex-direction: column;
            align-items: unset;
            justify-content: flex-start;
            gap: 48px;
        }

        @media screen and (max-width: $lpt) {
            margin-bottom: unset;
        }

        h2 {
            margin-bottom: 140px;

            @media screen and (max-width: $lpt) {
                height: unset;
                margin-bottom: unset;
            }
        }

        &.long-height {
            & .text-section-title {
                margin-bottom: 210px;

                @media screen and (max-width: $lpt) {
                    margin-bottom: unset;
                }
            }

            & .innerhero__content--right {
                height: calc(50% + 200px);
            }
        }

        &--right {
            max-width: 422px;
            width: 100%;
            color: $white;
            height: calc(50% + 100px);
            align-self: flex-end;

            @media screen and (max-width: $lpt) {
                align-self: flex-start;
            }

            &.on-two-lines {
                height: calc(50% + 140px);
            }

            p {
                color: white;
            }

            .scrollindicatorsn {
                display: flex;
                align-items: center;
                gap: 8px;
                position: absolute;
                bottom: 20px;
            }
        }
    }

    .projects {
        @media screen and (max-width: $tbl) {
            opacity: 0;
        }

        @include hover {
            .projects__list {
                a {
                    span {
                        opacity: 0.3;

                        &:last-child {
                            &:not(.number) {
                                opacity: 0.3;
                            }
                        }
                    }
                }
            }
        }

        h5 {
            margin-bottom: 32px;
        }

        &__list {
            display: grid;
            flex-wrap: wrap;
            list-style: none;
            gap: 16px;
            grid-template-rows: 1fr 1fr 1fr;
            grid-auto-flow: column;

            @media screen and (max-width: $tbl) {
                display: flex;
                flex-direction: column;
            }

            a {
                width: 180px;

                @include hover {
                    span {
                        opacity: 1 !important;

                        &:last-child {
                            &:not(.number) {
                                opacity: 1 !important;
                            }
                        }
                    }
                }

                span {
                    display: flex;
                    gap: 8px;
                    transition: $trans;

                    &:last-child {
                        &:not(.number) {
                            opacity: 0.5;
                        }
                    }
                }
            }
        }
    }
}

.workpin {
    height: 200vh;
}

.work-next-page {
    @include flex(center, center, 32px, column);

    height: 101vh;
    position: relative;

    &--wrap {
        height: 200vh;
        width: 100%;

        @media screen and (max-width: $tbl) {
            height: 101vh;
        }
    }

    &__link {
        display: none;
        pointer-events: none;
        position: absolute;
        bottom: -1000vh;
        background-color: red;
        z-index: 1999;
        width: 50px;
        height: 50px;
    }

    &__vid {
        object-fit: cover;
        height: 100lvh;
        width: 100vw;
        left: 0;
        position: absolute;
        overflow: hidden;

        & video,
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__title {
        text-align: center;

        @media screen and (min-width: $tbl) {
            position: absolute;
        }
    }

    &__num {
        position: absolute;
        color: $black;
        top: calc(90vh - 30px);

        @media screen and (max-width: $tbl) {
            display: none;
        }
    }

    & .nextproject__content--inner {
        position: relative;
        transform: unset;
        top: unset;
        left: unset;

        @media screen and (min-width: $tbl) {
            display: none;
        }

        & p {
            color: $black;
        }

        & svg,
        &::after {
            filter: invert(1);
        }
    }

    &__progress {
        display: flex;
        justify-content: left;
        align-items: center;
        max-width: 300px;
        width: 100%;
        position: absolute;
        top: 90vh;
        background-color: rgba($color: $black, $alpha: 20%);

        @media screen and (max-width: $tbl) {
            display: none;
        }

        &__line {
            width: 50%;
            height: 1px;
            background: $black;
        }
    }
}

.work-intro {
    position: absolute;
    top: 0;
    left: 0;
    height: 100lvh;
    width: 100vw;
    z-index: -1;

    &__vid {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100lvh;
        object-fit: cover;

        & video,
        & img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}
