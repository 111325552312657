.home-loader {
    // @include even-grid(3, 3, 0);
    position: relative;
    width: max-content;
    height: 100%;
    background-color: $black;
    display: flex;

    // overflow: hidden;
    z-index: 2;
    flex-wrap: nowrap;

    &__video {
        width: 100vw;
        height: 100%;
        object-fit: cover;
        position: relative;
        overflow: hidden;

        & video,
        & img {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
            left: 0;
            top: 0;
        }

        &--main {
            z-index: 3;

            // scale: 4;
        }
    }
}
