.scrollindicatorsn {
    overflow: hidden;

    & svg {
        overflow: hidden;
        border-radius: 50%;
        width: 21px;
        height: 21px;
    }

    & path {
        &:nth-of-type(2) {
            animation: pulse-y 3s 0s ease-in-out infinite;
            display: block;
            position: absolute;
            top: 0;
            transform-origin: center;
        }

        &:nth-of-type(3) {
            animation: pulse-y 3s 1.5s ease-in-out infinite;
            display: block;
            position: absolute;
            top: 0;
            transform-origin: center;
        }
    }

    &--mfb {
        display: flex;
        align-items: center;
        gap: 8px;
    }
}

@keyframes pulse-y {
    0% {
        transform: translate(6px, -280%);
    }

    35% {
        transform: translate(6px, 6px);
    }

    65% {
        transform: translate(6px, 6px);
    }

    100% {
        transform: translate(6px, 280%);
    }
}
