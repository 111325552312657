$dsk: 160em; // 2560px
$lpt-l: 90em; // 1440px
$lpt: 64em; // 1024px
$tbl: 48em; // 768px
$mbl-l: 56.25em; // 425px
$mbl-m: 40em; // 375px
$mbl-s: 21.25em; // 340px

$trans: all 0.35s ease;

:root {
    --spacer: 2.1vw;
    --negative-spacer: -2.1vw;
    --max-w: calc(90vw + (var(--spacer) * 2));
    --max-w-restrict: 80vw;

    @media (max-width: $tbl) {
        --max-w: 100vw;
        --max-w-restrict: 100vw;
    }

    @media (max-width: $mbl-l) {
        --spacer: 5.5vw;
        --negative-spacer: -5.5vw;
    }
}
