.btn {
    position: relative;
    display: flex;
    width: max-content;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 24px;
    overflow: hidden;
    transition: all 0.4s ease;

    &::before {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: $red;
        transform-origin: left;
        transform: scaleX(0);
        transition: transform 0.4s ease;
    }

    &.btngrey {
        background: rgb(0 0 0 / 8%);
    }

    &.btncolblack {
        span {
            color: $black;
        }

        svg {
            path {
                stroke: $black;
            }
        }
    }

    span,
    svg,
    svg path {
        position: relative;
        z-index: 2;
        transition: $trans;
    }

    @include hover {
        span {
            color: $white;
        }

        svg {
            path {
                stroke: $white;
            }
        }

        &::before {
            transform: scaleX(1);
        }
    }
}

.btnplay {
    position: relative;
    display: flex;
    width: max-content;
    padding: 8px 8px 8px 16px;
    align-items: center;
    gap: 8px;
    border-radius: 24px;
    background: rgb(255 255 255 / 16%);
    overflow: hidden;

    &::before {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: $red;
        transform: scaleX(0);
        transform-origin: right;
        transition: transform 0.4s;
    }

    span {
        position: relative;
        z-index: 2;
    }

    @include hover {
        &::before {
            transform: scaleX(1);
            transform-origin: left;
        }
    }
}
