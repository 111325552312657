.loader {
    @include flex(center, center, 0, row);

    width: 100vw;
    height: 100dvh;
    background-color: $black;
    color: $white;
    z-index: 100;
    position: fixed;
    justify-content: center;
    align-items: center;
    display: none;

    &__line {
        display: flex;
        justify-content: left;
        align-items: center;
        max-width: 300px;
        width: 100%;

        @media screen and (max-width: $mbl-l) {
            width: 60vw;
        }

        &--inner {
            width: 0;
            height: 1px;
            background: $white;
        }
    }
}
