$font-primary: "Times New Roman";
$font-secondary: "Arial";

h1,
h2,
h3,
h4,
h5,
h6,
th,
.btn,
.dropdown {
    font-family: $font-primary;
    font-style: normal;
    font-weight: normal;
}

.italic {
    font-style: italic;
}

.fontsn {
    font-family: $font-secondary;
}

body,
p,
ul,
ol,
input,
button,
select,
label,
* {
    font-family: $font-primary;
    font-style: normal;
    font-weight: 400;
}

small {
    font-size: 50%;
    vertical-align: top;
}

.pl small {
    font-size: 14px;
    line-height: 22px;
}

.text-big-xl {
    font-size: 140px;
    font-weight: 400;
    line-height: 155px;

    @media screen and (max-width: $lpt) {
        font-size: 56px;
        line-height: 56px;
    }
}

.text-big-data {
    font-size: 136px;
    font-weight: 400;
    line-height: 100%;

    @media screen and (max-width: $lpt) {
        font-size: 120px;
    }

    @media screen and (max-width: 480px) {
        font-size: 22vw;
    }
}

.text-landing-title {
    font-size: 120px;
    font-weight: 400;
    line-height: 120px;

    @media screen and (max-width: $lpt) {
        font-size: 64px;
        line-height: 64px;
    }
}

.desktop-only {
    @media screen and (max-width: $lpt) {
        display: none;
    }
}

.text-section-title {
    font-size: 72px;
    font-weight: 400;
    line-height: 72px;

    @media screen and (max-width: $lpt) {
        font-size: 56px;
        line-height: 56px;
    }
}

.text-nav-item {
    font-size: 8vh;
    font-weight: 400;
    line-height: 1;

    @media screen and (min-width: 1200px) and (max-height: 890px) {
        font-size: 8vh;
    }

    @media screen and (max-width: $lpt) {
        font-size: 7vw;
        line-height: 32px;
    }
}

.text-article-xl {
    font-size: 56px;
    font-weight: 400;
    line-height: 70px;

    @media screen and (max-width: $lpt) {
        font-size: 24px;
        line-height: 34px;
    }
}

.text-article-big {
    font-size: 50px;
    font-weight: 400;
    line-height: 60px;
    max-width: 957px;

    @media screen and (max-width: $lpt) {
        font-size: 35px;
        line-height: 41px;
    }
}

.text-quote {
    font-size: 36px;
    line-height: 46px;
    position: relative;

    @media screen and (max-width: $lpt) {
        font-size: 24px;
        line-height: 34px;
    }

    & span {
        position: absolute;
        top: 0;
        left: 0;
        transform: translateX(-100%);
    }
}

.text-body-copy-big {
    font-size: 26px;
    line-height: 36px;
    font-weight: 400;

    @media screen and (max-width: $lpt) {
        font-size: 18px;
        line-height: 26px;
    }
}

.text-body-copy {
    font-size: 20px;
    line-height: 28px;
    font-weight: 400;

    @media screen and (max-width: $lpt) {
        font-size: 18px;
        line-height: 26px;
    }
}

.text-body-copysn {
    font-family: $font-secondary;
    font-size: 18px;
    line-height: 22px;
    font-weight: 400;

    @media screen and (max-width: $lpt) {
        font-size: 16px;
        line-height: 24px;
    }
}

.text-sub-heading {
    font-family: $font-secondary;
    font-size: 18px;
    line-height: 22px;
    font-weight: 700;
    margin-bottom: 10px;

    @media screen and (max-width: $lpt) {
        font-size: 16px;
        line-height: 20px;
    }
}

.text-small-detail {
    font-family: $font-secondary;
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;

    @media screen and (max-width: $lpt) {
        font-size: 14px;
        line-height: 20px;
    }
}

.text-small-detail-bold {
    font-family: $font-secondary;
    font-size: 14px;
    line-height: 22px;
    font-weight: 700;

    @media screen and (max-width: $lpt) {
        font-size: 14px;
        line-height: 20px;
    }
}

.text-caption {
    font-family: $font-secondary;
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;

    & small {
        font-size: 80%;
        vertical-align: sub;
    }
}

.color-black {
    color: $black;
}

.color-white {
    color: $white;
}

.color-red {
    color: $red;
}

.color-grey {
    color: $grey;
}

.color-grey2 {
    color: $grey2 !important;
}

.color-grey3 {
    color: $grey3;
}
