.fof {
    @include flex(center, center, 32px, column);

    height: 100dvh;
    width: 100vw;
    max-width: 690px;
    margin: 0 auto;
    text-align: center;
    padding: 0 20px;

    & .btn {
        margin-top: 50px;
    }
}
