// Utils - Utilities
@import "utils/reset";
@import "utils/mixins";
@import "utils/variables";
@import "utils/colours";
@import "components/btn";
@import "components/scrollindicatorsn";

// Base - Global base point styling
@import "base/typography";
@import "base/fonts";
@import "base/global";
@import "base/misc";
@import "layout/header";
@import "layout/loader";
@import "layout/cookie-banner";
@import "layout/404";
@import "modules/home-loader";
@import "modules/home-slider";
@import "modules/innerhero";
@import "modules/innercontent";
@import "modules/nextproject";
@import "modules/worksinner";
@import "modules/c-asset";

// Base end
.ar23 {
    main {
        margin: 0 !important;
        padding: 0;
    }
}
