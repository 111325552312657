.innercontent {
    // position: relative;
    padding: 120px 0;

    @media screen and (max-width: $lpt) {
        padding: 80px 0;
    }

    &--less-pad {
        padding-bottom: calc(120px - 56px);
    }

    &.pb-0 {
        padding-bottom: 0;
    }

    &.bgtrans {
        padding-top: 0;
        background: transparent;
        overflow: hidden;
    }

    &.bgred {
        & .innercontent__scrollprogress--line {
            background-color: $white;
        }
    }

    &__scrollprogress {
        position: fixed;
        left: 0;
        top: 0;
        width: 4px;
        height: 100%;
        z-index: 1000;

        @media screen and (max-width: $lpt) {
            display: none;
        }

        &--line {
            position: sticky;
            top: 0;
            left: 0;
            width: 4px;
            height: 0;
            background: $red;
        }
    }

    &__container {
        max-width: 100%;
        width: 100%;
        margin: 0 auto;
        padding: 0 106px;
        display: flex;
        gap: 55px;

        @media screen and (max-width: $lpt) {
            flex-direction: column;
            padding: 0 20px;
            gap: 0;
        }

        &--fade {
            background: rgb(255 255 255 / 50%);
        }
    }

    &__right {
        width: 100%;
    }

    &__content {
        // position: relative;
        max-width: 690px;
        width: 100%;
        margin: 0 auto;
        transform: translateX(-105px);

        &.fluid-width {
            max-width: 100%;
            margin: 0;
            transform: unset;
        }

        &.middle {
            transform: translateX(0);
        }

        &--oyin {
            @media screen and (max-width: 1330px) {
                display: flex;
                flex-direction: column;
                gap: 20vw;
            }

            @media screen and (max-width: $tbl) {
                gap: 0;
            }
        }

        @media screen and (max-width: $lpt) {
            width: 100%;
            transform: translateX(0);
        }
    }

    &__author {
        position: relative;
        display: flex;
        align-items: center;
        gap: 16px;
        margin-bottom: 40px;

        &--img {
            width: 60px;
            height: 60px;

            img {
                width: 60px;
                height: 60px;
                border-radius: 50%;
                object-fit: cover;
            }
        }
    }

    &__heading {
        margin-bottom: 56px;

        h2 {
            margin-bottom: 56px;
        }

        p {
            &:not(:last-child) {
                margin-bottom: 30px;
            }

            &.max-width {
                max-width: 422px;
                width: 100%;
            }
        }
    }

    &__oyincontent {
        @media screen and (max-width: $lpt) {
            margin-bottom: 0;
        }

        &.place-end {
            width: max-content;
            margin-left: auto;

            @media screen and (max-width: $lpt) {
                max-width: 100%;
            }
        }

        h2 {
            position: relative;
            margin-bottom: 16px;
            z-index: 2;
        }

        p {
            position: relative;
            max-width: 422px;
            width: 100%;
            z-index: 2;

            &.max-width-sn {
                max-width: 496px;
            }

            & small {
                vertical-align: baseline;
                display: inline-block;
                transform: translateY(15%);
            }
        }

        ul {
            display: flex;
            flex-direction: column;
            gap: 16px;
            max-width: 422px;
            width: 100%;
            position: relative;
            z-index: 2;

            li {
                display: flex;
                gap: 16px;

                span {
                    &:first-child {
                        width: 60px;
                    }

                    &:last-child {
                        width: calc(100% - 80px);
                    }
                }
            }
        }

        .innerjson {
            position: relative;
            width: 100%;
            min-height: 80vh;
        }

        &--video {
            position: relative;
            width: 100%;
            height: 55vw;
            margin-top: -20rem;

            @media screen and (max-width: $lpt) {
                height: auto;
                margin-top: 80px;
            }

            @media screen and (max-width: $lpt) {
                margin-top: 0;
                width: 100vw;
                margin-left: -20px;
            }

            video,
            img {
                position: absolute;
                width: 1231px;
                left: 50%;
                max-width: unset;
                transform: translateX(-50%);
                pointer-events: none;

                @media screen and (max-width: $lpt) {
                    position: relative;
                    width: 100%;
                    left: unset;
                    transform: translateX(0);
                }
            }
        }
    }

    &__block {
        margin-bottom: 56px;

        @media screen and (min-width: 1200px) {
            scroll-padding-top: 120px;
        }

        &--more-pad {
            margin-bottom: 120px;
        }

        p {
            &:not(:last-child) {
                margin-bottom: 30px;
            }

            &.max-width {
                max-width: 422px;
                width: 100%;
            }
        }
    }

    &__numbers {
        display: flex;
        gap: 60px;
        margin-bottom: 56px;

        @media screen and (max-width: $lpt) {
            flex-direction: column;
        }
    }

    &__quote {
        margin-bottom: 56px;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    &__sidebar {
        max-width: 211px;
        width: 100%;
        z-index: 20;

        &--menu {
            display: flex;
            flex-direction: column;
            gap: 14px;
            list-style: none;

            @media screen and (max-width: $lpt) {
                display: none;
            }

            &--mobile {
                display: none;

                & li {
                    display: block !important;
                }

                @media screen and (max-width: $lpt) {
                    display: flex;
                    flex-direction: row;
                    position: fixed;
                    width: 100%;
                    margin-left: -20px;
                    justify-content: center;
                    gap: 32px;
                    bottom: 20px;
                }
            }

            li {
                a {
                    transition: $trans;

                    @include hover {
                        color: $red !important;
                    }

                    &.active {
                        color: $red !important;
                    }
                }

                &.sidebar--red {
                    & a {
                        color: rgba($color: $white, $alpha: 48%) !important;

                        &.active {
                            color: $white !important;
                        }

                        @include hover {
                            color: $white !important;
                        }
                    }
                }

                &.sidebar--dark {
                    & a {
                        color: $white !important;

                        &.active {
                            color: $red !important;
                        }

                        @include hover {
                            color: $red !important;
                        }
                    }
                }
            }
        }
    }

    &__oyinnumber {
        position: relative;
        margin-top: 55px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 32px 55px;

        @media screen and (max-width: $lpt) {
            grid-template-columns: repeat(2, 1fr);
            gap: 32px 0;
        }

        @media screen and (max-width: $tbl) {
            grid-template-columns: repeat(1, 1fr);
        }

        &--col {
            position: relative;
            flex-grow: 1;

            &.cs-width-1 {
                width: 225px;
            }

            &.cs-width-2 {
                width: 296px;
            }

            h2 {
                font-size: 6vw;
                font-weight: 400;
                line-height: 104px;
                display: flex;

                @media (max-width: 964px) {
                    font-size: 90px;
                }
            }
        }
    }

    &__textbanner {
        &--banner {
            position: absolute;
            left: 0;
            width: 100%;
            height: 100vh;

            &::before {
                position: absolute;
                content: "";
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                background: linear-gradient(0deg, rgb(0 0 0 / 64%) 0%, rgb(0 0 0 / 64%) 100%);
                z-index: 1;
            }

            img,
            video {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &--text {
            position: relative;
            width: 100%;
            height: 100vh;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            z-index: 2;
        }
    }

    &__contentbanner {
        &--banner {
            position: absolute;
            left: 0;
            width: 100%;
            height: 100vh;

            &::before {
                position: absolute;
                content: "";
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                background: linear-gradient(0deg, rgb(0 0 0 / 64%) 0%, rgb(0 0 0 / 64%) 100%);
                z-index: 1;
            }

            img,
            video {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &--text {
            position: relative;
            max-width: 422px;
            min-height: 100vh;
            padding-top: 120px;
            width: 100%;
            z-index: 2;

            p {
                &:not(:last-child) {
                    margin-bottom: 20px;
                }

                &:last-child {
                    max-width: 333px;
                    color: rgba($color: $white, $alpha: 50%);
                }
            }
        }
    }

    &__datatowork {
        position: relative;
        display: flex;
        justify-content: center;
        gap: 114px;
        padding: 160px 0;
        margin-bottom: 44px;

        @media screen and (max-width: $lpt) {
            padding: 80px 0;
            gap: 40px;
            justify-content: space-between;
        }

        @media screen and (max-width: $mbl-l) {
            flex-direction: column;
        }

        @media screen and (max-width: $tbl) {
            &.bggrey {
                margin-bottom: 0;

                & > * {
                    z-index: 1;
                }

                &::before {
                    position: absolute;
                    content: "";
                    width: 100vw;
                    top: 0;
                    left: 100%;
                    transform: translate(calc(-100vw + 106px), 0);

                    @media screen and (max-width: $lpt) {
                        transform: translate(0);
                        left: -20px;
                    }

                    background: #e0e0e0;
                    height: 100%;
                }
            }
        }

        &--img {
            max-width: 422px;
            width: 100%;
            position: relative;

            @media screen and (max-width: 645px) {
                max-width: 100%;
            }

            p {
                max-width: 333px;
                margin-top: 16px;
                position: absolute;

                @media screen and (max-width: $mbl-l) {
                    position: unset;
                    margin-top: 0;
                }
            }

            & img {
                width: 100%;
                height: auto;
                object-fit: cover;
            }
        }

        &--text {
            display: flex;
            flex-direction: column;
            justify-content: center;
            max-width: 422px;
            width: 100%;

            p {
                &:not(:last-child) {
                    margin-bottom: 25px;
                }
            }
        }
    }

    &__datanumbers {
        position: relative;
        display: flex;
        gap: 64px 113px;
        flex-wrap: wrap;
        padding: 120px 0;

        @media screen and (max-width: $lpt) {
            padding: 80px 0;
        }

        &::before {
            position: absolute;
            content: "";
            width: 100vw;
            height: 100%;
            top: 0;
            left: 50%;
            background: #f2f2f2;
            margin-left: -133px;
            transform: translateX(-50%);

            @media screen and (max-width: $lpt) {
                margin-left: 0;
            }
        }

        &--block {
            position: relative;
            max-width: 422px;
            width: 100%;

            h2 {
                margin-bottom: 56px;
            }
        }
    }

    &.bgwhite {
        background: $white;
    }

    &.bgred {
        background: #e9222c;

        .innercontent__sidebar {
            ul {
                li {
                    a {
                        color: rgba($color: $white, $alpha: 48%) !important;

                        @include hover {
                            color: rgba($color: $white, $alpha: 80%) !important;
                        }

                        &.active {
                            color: rgba($color: $white, $alpha: 100%) !important;
                        }
                    }
                }
            }
        }
    }
}

[data-content-entry="chairsStatement"] {
    .innercontent__sidebar {
        margin-top: 6px;
    }
}

[data-ourwork="true"] {
    .worksectiontitle .scrollindicatorsn.text-small-detail {
        display: none;
    }
}

[data-content-entry="Project1"] {
    .innercontent__sidebar {
        @media screen and (min-width: $lpt) {
            margin-top: 35vh;
        }
    }

    .innercontent__container.innercontent__container--fade {
        background: rgba($color: #fff, $alpha: 100%);
    }

    .work-intro__vid {
        filter: brightness(0.2);
    }
}
