.worksectiontitle {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;

    & .scrollindicatorsn {
        z-index: 2;
        display: flex;
        align-items: center;
        gap: 8px;
        position: absolute;
        bottom: 40px;

        @media screen and (max-width: $lpt) {
            bottom: 80px;
        }
    }

    h2 {
        position: relative;

        @media screen and (max-width: $tbl) {
            margin-bottom: 145px;
        }
    }
}

.worksectionsemititle {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    // &::before {
    //     position: absolute;
    //     content: "";
    //     width: 100%;
    //     height: 101vh;
    //     left: 0;
    //     background:
    //         linear-gradient(180deg, rgb(255 255 255 / 0%) 0%, #fff 75.16%),
    //         linear-gradient(0deg, rgb(255 255 255 / 50%) 0%, rgb(255 255 255 / 50%) 100%);
    // }

    h4,
    & h3 {
        position: relative;
        max-width: 957px;
    }

    h2 {
        position: relative;
        margin-bottom: 40px;
    }

    p {
        position: relative;
        max-width: 670px;
        width: 100%;
    }
}

[data-content-entry="p5NatureBasedFuture"] .worksectionsemititle {
    .text-landing-title {
        font-size: 136px;
        font-weight: 400;
        line-height: 136px;

        @media screen and (max-width: $lpt) {
            font-size: 20vw;
            line-height: 100%;
        }
    }
}

.videotextbanner {
    min-height: 200vh;

    &--no-scale {
        min-height: 100vh;
        margin-top: -1px;
    }

    &.nograd {
        & .videotextbanner__video {
            filter: unset !important;
        }
    }

    &__inner {
        position: absolute;
        width: 100vw;
        left: 0;
        height: 100vh;
    }

    &__video {
        height: 100vh;
        overflow: hidden;
        filter: brightness(0.6);

        video,
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__text {
        position: absolute;
        max-width: 422px;
        width: 100%;
        z-index: 3;
        top: 50%;
        margin-right: -78px;
        right: 50%;
        transform: translate(0%, -50%);

        @media screen and (max-width: $mbl-l) {
            left: 20px;
            right: unset;
            width: 422px;
            max-width: calc(100% - 40px);
        }

        h5 {
            margin-bottom: 10px;
        }

        .text-caption {
            max-width: 333px;
            margin-top: 40px;

            @media screen and (max-width: $mbl-l) {
                margin-top: 16px;
            }
        }

        &.leftalign {
            margin-left: 190px;
            margin-right: unset;
            right: unset;
            left: 50%;
            transform: translate(0%, -50%);

            @media screen and (max-width: 1270px) {
                margin-left: 0;
            }

            @media screen and (max-width: $mbl-l) {
                left: 20px;
                max-width: calc(100% - 40px);
                width: 422px;
            }
        }

        &.aligncenter {
            max-width: 600px;
            width: 100%;
            left: 50%;
            transform: translate(-28%, -50%);

            @media screen and (max-width: 1200px) {
                left: 20px;
                transform: translate(0%, -50%);
                width: calc(100% - 40px);
            }
        }
    }
}

.textimgbanner,
.textbanner {
    min-height: 101vh;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: $mbl-l) {
        min-height: 130vh;
    }

    &--scale {
        min-height: 200vh;
        align-items: flex-start;

        & .textimgbanner__inner {
            position: absolute;
            width: 100vw;
            left: 0;
            height: 100vh;
            display: flex;
            align-items: center;

            & .textimgbanner__text {
                max-width: 600px;
                width: 100%;
                margin: 0 auto;
                padding: 0 20px;
                transform: translate(25%, 0%);

                @media screen and (max-width: 1200px) {
                    transform: translate(0%, 0%);
                }

                @media screen and (max-width: $mbl-l) {
                    padding: 0;
                }
            }
        }

        & .textimgbanner__img {
            overflow: hidden;
        }
    }

    &__img {
        position: absolute;
        width: 100%;
        height: 101vh;
        left: 0;

        @media screen and (max-width: $mbl-l) {
            height: 130vh;
        }

        &::before {
            position: absolute;
            content: "";
            width: 100%;
            height: 100%;
            left: 0;
            background: linear-gradient(0deg, rgb(0 0 0 / 60%) 0%, rgb(0 0 0 / 60%) 100%);
            z-index: 1;
        }

        img,
        video {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &--tall {
        height: 140vh;

        & .textimgbanner__img {
            height: 140vh;
        }
    }

    &.greygrad {
        .textimgbanner__img {
            &::before {
                background: linear-gradient(270deg, rgb(255 255 255 / 50%) 0%, rgb(255 255 255 / 0%) 43.67%);
            }
        }
    }

    &.nograd {
        & .textimgbanner__img {
            &::before {
                display: none;
            }
        }
    }

    &__text {
        width: 100%;
        text-align: center;
        z-index: 2;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        max-width: 957px;
        padding: 0 20px;

        @media screen and (max-width: $mbl-l) {
            padding: 0;
        }

        h2,
        h3 {
            margin-bottom: 40px;
        }

        p {
            max-width: 670px;
            width: 100%;
        }

        &.leftalign {
            position: absolute;
            max-width: 422px;
            width: 100%;
            text-align: left;
            justify-content: flex-start;
            align-items: flex-start;
            margin-left: 190px;
            left: 50%;
            transform: translate(0%, 0%);

            @media screen and (max-width: 1270px) {
                margin-left: 0;
            }

            @media screen and (max-width: $mbl-l) {
                left: 20px;
                max-width: calc(100% - 40px);
                width: 422px;
            }

            p {
                &.text-caption {
                    margin-top: 40px;
                    max-width: 333px;

                    @media screen and (max-width: $mbl-l) {
                        margin-top: 16px;
                    }
                }
            }
        }
    }
}

.textbanner {
    position: relative;

    &::before {
        position: absolute;
        content: "";
        width: 100vw;
        height: 100%;
        left: 100%;
        background: #171717;
        transform: translate(calc(-100vw + 106px), 0);
    }

    &.bgwhite {
        &::before {
            background: $white;
        }
    }
}

.textnumberblock {
    position: relative;
    padding: 120px 0;
    display: flex;
    flex-direction: column;
    gap: 88px;

    &::before {
        position: absolute;
        content: "";
        width: 100vw;
        height: 100%;
        top: 0;
        left: 100%;
        background: $white;
        transform: translate(calc(-100vw + 106px), 0);

        @media screen and (max-width: $lpt) {
            transform: translate(0);
            left: -20px;
        }
    }

    & small {
        vertical-align: baseline;
        display: inline-block;
        transform: translateY(15%);
    }

    &__single {
        position: relative;
        z-index: 2;
        max-width: 957px;
        width: 100%;
        margin: 0 auto;

        h2 {
            margin-bottom: 32px;
        }

        p {
            max-width: 422px;
        }
    }

    &.bggrey {
        margin-bottom: 0;
        padding: 120px 0;

        &::before {
            background: #f2f2f2;
            height: 100%;
        }
    }
}

.clientquote {
    position: relative;
    height: 101vh;
    display: flex;
    justify-content: center;
    align-items: center;

    &::before {
        position: absolute;
        content: "";
        left: 100%;
        width: 100vw;
        height: 101vh;
        background: #171717;
        transform: translate(calc(-100vw + 106px), 0);

        @media screen and (max-width: $lpt) {
            left: -20px;
            transform: unset;
        }
    }

    &__wrapper {
        position: relative;
        max-width: 600px;
        width: 100%;
    }

    &__quote {
        margin-bottom: 16px;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    &.bgred {
        &::before {
            background: $red;
        }
    }
}

.numberscols {
    position: relative;
    padding: 120px 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 88px 120px;
    max-width: 957px;
    margin: 0 auto;

    @media screen and (max-width: 750px) {
        grid-template-columns: 1fr;
    }

    &::before {
        position: absolute;
        content: "";
        width: 100vw;
        height: 100%;
        left: 100%;
        background: $white;
        top: 0;
        transform: translate(calc(-100vw + 106px), 0);
    }

    &__col {
        position: relative;

        h2 {
            margin-bottom: 32px;
        }

        p {
            max-width: 422px;
            width: 100%;
        }
    }
}

.imgbanner {
    position: relative;
    width: 100%;
    height: 101vh;

    &__img {
        position: absolute;
        width: 100vw;
        height: 100%;
        left: 100%;
        background: $white;
        top: 0;
        transform: translate(calc(-100vw + 106px), 0);

        @media screen and (max-width: $lpt) {
            transform: translateX(-20px);
            left: 0;
        }

        &::before {
            position: absolute;
            content: "";
            width: 100%;
            height: 100%;
            background:
                linear-gradient(
                    180deg,
                    rgb(0 0 0 / 20%) 0%,
                    rgb(0 0 0 / 12%) 7.81%,
                    rgb(0 0 0 / 0%) 22.4%,
                    rgb(0 0 0 / 0%) 75%,
                    rgb(0 0 0 / 12%) 91.15%,
                    rgb(0 0 0 / 20%) 100%
                ),
                linear-gradient(0deg, rgb(0 0 0 / 20%) 0%, rgb(0 0 0 / 20%) 100%);
            z-index: 1;
        }

        img,
        video {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

.worktwocols {
    position: relative;
    padding: 150px 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0 112px;
    max-width: 957px;
    margin: 0 auto;

    @media screen and (max-width: $tbl) {
        grid-template-columns: 1fr;
        gap: 112px;
    }

    &::before {
        position: absolute;
        content: "";
        width: 100vw;
        height: 100%;
        left: 100%;
        background: $white;
        top: 0;
        transform: translate(calc(-100vw + 106px), 0);
    }

    & small {
        font-size: 80%;
        vertical-align: baseline;
    }

    &__col {
        position: relative;
        width: 100%;

        &--img {
            width: 100%;
            margin-bottom: 18px;

            img {
                width: 100%;
                object-fit: cover;
            }

            p {
                margin-top: 16px;
            }
        }
    }
}

.textanimbanner {
    position: relative;
    height: 101vh;
    display: flex;
    align-items: center;
    justify-content: center;

    &::before {
        position: absolute;
        content: "";
        width: 100vw;
        height: 100%;
        left: 100%;
        background: $white;
        top: 0;
        transform: translate(calc(-100vw + 106px), 0);
    }

    &__text {
        position: relative;
        text-align: center;

        h2 {
            margin-bottom: 40px;
        }

        p {
            max-width: 690px;
            width: 100%;
        }
    }
}

.next-page {
    min-height: 300vh;

    .next-page-elem {
        position: sticky;
        top: 0;
    }

    .next-page-link {
        display: none;
    }
}
