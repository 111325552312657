a:focus,
button:focus,
input:focus,
select:focus,
textarea:focus {
    box-shadow: none;
    -webkit-tap-highlight-color: transparent;
}

img {
    display: block;
    font-weight: 300;
    height: auto;
    line-height: 2;
    position: relative;
    text-align: center;
    width: 100%;

    &::before {
        content: "We're sorry, the image below is broken";
        display: block;
        margin-bottom: 1rem;
    }

    *::after {
        content: "(url: " attr(src) ")";
        display: block;
        font-size: 1.2rem;
    }
}

.mobile-image-slider {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;

    & img {
        @include abs-background;

        opacity: 0;
        pointer-events: none;
        transition: all 0.8s ease;
        transition-delay: 0.5s;

        &.active {
            opacity: 1;
            transition-delay: 0s;
            position: relative;
        }
    }
}

input,
select {
    border-radius: 0;
}

*::selection {
    background-color: $black;
    color: $white;
}
